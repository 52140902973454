import { GetStaticProps, NextPage } from 'next';
import React from 'react';
import nameSpacesConfig from '@/config/config.nameSpaces.json';
import { Footer } from '@/containers/PageLayout/components/Footer/Footer';
import { Navbar } from '@/containers/PageLayout/components/Navbar/Navbar';
import { PageError } from '@/containers/PageLayout/components/PageError/PageError';
import { SideNavbar } from '@/containers/PageLayout/components/SideNavbar';
import { PageLayout } from '@/containers/PageLayout/PageLayout';
import { checkForDefaultLocale } from '@/lib/i18n/checkForDefaultLocale';
import { getServerSideTranslations } from '@/lib/i18n/getServerSideTranslations';
import { PageDataContextProvider } from '@/lib/pageData/pageDataContext';
import { CMSData } from '@/types/CMSData';
import { importCmsJson } from '@/utils/importCmsJson';

interface Props {
  data: CMSData<'default'> | null;
}

const NotFound: NextPage<Props> = ({ data }) => (
  <PageDataContextProvider data={data}>
    <PageLayout
      metaTags={{
        title: '404 Not Found | Swapfiets',
        noindex: true,
      }}
      navBar={
        <Navbar
          rightSlot={data && <SideNavbar />}
          showNotificationBanner={!!data}
        />
      }
      footer={data && <Footer />}
    >
      <PageError type="notFound" link={data?.navLinks.contact} />
    </PageLayout>
  </PageDataContextProvider>
);

export default NotFound;

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const data = checkForDefaultLocale(locale!)
    ? null
    : await importCmsJson(locale!, 'default');

  return {
    props: {
      data,
      ...(await getServerSideTranslations(locale!, [
        nameSpacesConfig.common,
        nameSpacesConfig.pages,
      ])),
    },
  };
};
